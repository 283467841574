import React from 'react'
import Gallery from 'src/components/gallery'
import Helmet from 'react-helmet'
import Layout from 'src/components/layout'

import { graphql } from 'gatsby'
export default ({ data }) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Photo Gallery - Plumbing and Backflow Prevention -{' '}
          {data.site.siteMetadata.title}
        </title>
      </Helmet>
      <h1>Photo Gallery - Plumbing and Backflow Prevention</h1>
      <Gallery data={data.allFile} />
    </div>
  </Layout>
)

export const query = graphql`
  query GalleryPlumbing {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: "gallery/gallery-plumbing" } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      ...GalleryItems
    }
  }
`
